var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"alerts","fluid":"","tag":"section"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.carga),callback:function ($$v) {_vm.carga=$$v},expression:"carga"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"text-h4 font-weight-light white--text"},[_vm._v(" "+_vm._s(_vm.textoMensaje)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","width":"100%","permanent":""}},[_c('div',{staticClass:"top-main d-flex flex-column align-center justify-center"},[_c('v-img',{attrs:{"src":"assets/logo_la_n.png","max-width":"250"},on:{"click":function($event){_vm.$router.push(
            { path: '/data' },
            function () {
              _vm.$emit('reload')
            }
          )}}})],1),_c('div',[_c('v-dialog',{attrs:{"width":"100%","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogConsulta),callback:function ($$v) {_vm.dialogConsulta=$$v},expression:"dialogConsulta"}},[_c('base-material-card',{attrs:{"icon":"mdi-seal","color":"primary","title":_vm.tituloDialog}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsFiltro,"hide-selected":"","autofocus":"","item-text":"nombre","item-value":"cod","clearable":"","label":"Busque el tipo de filtro","placeholder":"Escriba o seleccione el filtro"},on:{"input":function($event){return _vm.getFiltroSeleccionado()}},model:{value:(_vm.filtroSeleccionado),callback:function ($$v) {_vm.filtroSeleccionado=$$v},expression:"filtroSeleccionado"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"tile":"","block":"","color":"primary"},on:{"click":function($event){return _vm.leerData()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"tile":"","block":"","color":"green"},on:{"click":function($event){return _vm.generarExcel()}}},[_c('v-icon',[_vm._v(" mdi-file-excel ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"ma-0",staticStyle:{"max-width":"750px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar...","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataConsulta,"search":_vm.search,"light":"","dense":"","fixed-header":"","loading":_vm.carga,"loading-text":"Cargando, por favor espere","footer-props":{
                    'items-per-page-options': [10, 50, -1],
                    'items-per-page-text': 'Mostrando:',
                    'items-per-page-all-text': 'Todos'
                  },"header-props":{
                    'sortByText': 'Ordenar por'
                  },"no-data-text":"No hay datos registrados","no-results-text":"No se encontraron resultados"},scopedSlots:_vm._u([{key:"item.estado_boletin",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.estado_boletin === 'VALIDADO' ? 'green' : item.estado_boletin === 'OBSERVADO' ? 'orange' :'blue',"dark":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(" "+_vm._s(item.estado_boletin === 'VALIDADO' ? 'mdi-check-circle' : item.estado_boletin === 'OBSERVADO' ? 'mdi-alert-box' :'mdi-refresh'))])],1),_vm._v(" "+_vm._s(item.estado_boletin)+" ")],1)]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.cerrarDialog()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1),(!_vm.carga)?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('base-material-card',{attrs:{"color":"primary","width":"550","max-width":"85%","icon":"mdi-file-document","title":"EXPEDIENTES CON SENTENCIA SIN BOLETINES"}},[_c('div',{staticClass:"mb-n4 mt-2 text-caption font-weight-light"},[_vm._v(" Consulte sobre expedientes que han descargado sentencias del tipo condenatorias para Juzgados Unipersonales y Colegiados (HITO 420) y del tipo terminación anticipada para Juzgados de Investigación Preparatoria (HITO O45) que no han generado el boletín electrónico "),_c('br'),_vm._v("Para realizar la búsqueda de clic en consultar ")]),_c('v-card',{staticClass:"d-flex flex-column red--text align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.consultarData()}}},[_vm._v(" consultar ")])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-body-2 white--text text-center",on:{"click":function($event){return _vm.$router.push('/cerrar_sesion')}}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © CSJLA ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }